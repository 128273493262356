import React from "react";

function Payment({ selectedPlan }) {
  const successUrl = `https://wemonitize4u.com/payment/success/?id=${selectedPlan?._id}`;

  return (
    <div
      className="modal fade model-center"
      id="paymentmodel"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Proceed To Payment
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-between my-3">
              <div className="fw-bold">Subscription Type</div>
              <span className="badge bg-info text-white">
                {selectedPlan?.title}
              </span>
            </div>
            <div className="d-flex justify-content-between my-3">
              <div className="fw-bold">Subscription Category</div>
              <span className="badge bg-info text-white">
                {selectedPlan?.subTitle}
              </span>
            </div>
            <div className="d-flex justify-content-between my-3">
              <div className="fw-bold">Subscription Amount</div>
              <span className="badge bg-info text-white">
                ${selectedPlan?.price}
              </span>
            </div>
            <form
              action="https://www.coinpayments.net/index.php"
              method="post"
              target="_top"
            >
              <input type="hidden" name="cmd" value="_pay_simple" />
              <input type="hidden" name="reset" value="1" />
              <input
                type="hidden"
                name="merchant"
                value={process.env.REACT_APP_COINPAYMENTS_MERCHANT}
              />
              <input type="hidden" name="currency" value="USD" />
              <input type="hidden" name="amountf" value={selectedPlan?.price} />
              <input
                type="hidden"
                name="item_name"
                value={selectedPlan?.title}
              />
              <input type="hidden" name="success_url" value={successUrl} />
              <input
                type="hidden"
                name="cancel_url"
                value="https://wemonitize4u.com/user/dashboard/"
              />
              <div className="d-flex justify-content-end">
                <input
                  type="image"
                  src="https://www.coinpayments.net/images/pub/buynow-grey.png"
                  alt="Buy Now with CoinPayments.net"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
