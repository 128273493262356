import React from "react";

function About() {
  return (
    <div className="container-xxl py-5" id="about">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <img className="img-fluid" src="/assets/img/about.png" alt="icon" />
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="h-100">
              <h1 className="display-6">About Us</h1>
              <p className="fs-5 mb-4" style={{ color: "#16D5FF" }}>
                The Most Trusted Monitize Platform
              </p>
              <p>
                At <strong>WeMonitize4U</strong>, we are dedicated to simplifying the world of
                financial conversions for our users. Founded with the goal of
                providing seamless currency exchanges, we serve as a bridge
                between fiat and cryptocurrency. We believe that converting
                assets should be as simple as possible, whether you’re dealing
                with mainstream fiat currencies like USD, AED, EUR, or rarely
                traded crypto tokens. Over the next five years, our vision is
                to become the go-to platform for global asset conversion,
                catering to both individual users and large enterprises. Our
                focus is on continuous improvement, leveraging advanced
                technologies to enhance security, speed, and flexibility.
              </p>

              <p className="mt-3">
                We aim to expand our services to include more currencies,
                introduce new features for institutional clients, and integrate
                decentralized finance solutions, all while maintaining the
                highest compliance standards. With a customer-first approach, we
                ensure a smooth experience at every step, from conversion to
                withdrawal. Our long-term vision is to empower users worldwide,
                giving them full control over their financial assets in a
                secure, transparent, and efficient environment. Join us on this
                journey to redefine asset conversion.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
