import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { createSubscription } from '../api/Api';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../redux/actions/userProfile';

function PaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation(); 
  const searchParams = new URLSearchParams(location.search);
  const subscriptionId = searchParams.get('id');

  const userId = useSelector((state) => state.userData.user._id);
  const [loading, setLoading] = useState(false);
const dispatch = useDispatch();
  const hasRun = useRef(false);

  const sendPaymentData = async () => {
    setLoading(true);
    try {
      const credentials = { userId, subscriptionId };
      const result = await createSubscription(credentials);
      if (result.status === "success") {
        toast.success("Payment Verified");
        dispatch(setUser(result?.user));
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        toast.error(result.message || "Failed to complete the payment.");
      }
    } catch (error) {
      toast.error("An error occurred during the payment verification.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasRun.current === false && subscriptionId && userId) {
      sendPaymentData();
      hasRun.current = true;
    }
    // eslint-disable-next-line
  }, [subscriptionId, userId]);

  return (
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            {loading ? (
              <div>
                <i className="spinner-border text-info" style={{ fontSize: '3rem' }} role="status" />
                <h1 className="display-5">Processing Payment...</h1>
                <p className="mb-4">Please wait while we verify your payment.</p>
              </div>
            ) : (
              <div>
                <i className="bi bi-check-circle display-1 text-info" />
                <h1 className="display-1">Success</h1>
                <h1 className="mb-4">Payment Successful</h1>
                <p className="mb-4">
                  Congratulations! Your payment has been completed.
                </p>
                <a className="btn btn-info text-white py-3 px-4" href="/login">
                  Go Back To Home
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
