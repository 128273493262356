import React from 'react';

function Faqs() {
  return (
    <div className="container-xxl py-5" id='faqs'>
      <div className="container">
        <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 500 }}>
          <h1 className="display-6">FAQs</h1>
          <p className="fs-5 mb-5" style={{ color: '#16D5FF' }}>Frequently Asked Questions</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="accordion" id="accordionExample">
              {faqs.map((faq, index) => (
                <div key={index} className="accordion-item wow fadeInUp" data-wow-delay={`${0.1 * (index + 1)}s`}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === 0} aria-controls={`collapse${index}`}>
                      {faq.question}
                    </button>
                  </h2>
                  <div id={`collapse${index}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const faqs = [
  { question: "What services does WeMonitize4U offer?", answer: "We offer cryptocurrency and fiat currency conversion services, allowing users to convert their assets into stablecoins or withdraw directly to bank accounts." },
  { question: "What types of currencies can I convert?", answer: "You can convert fiat currencies like USD, AED, EUR, and various cryptocurrency tokens into USDT ERC20 or USDT TRC20." },
  { question: "Do I need to verify my account?", answer: "Verification (KYC) is required for fiat withdrawals to bank accounts. No verification is needed for cryptocurrency conversions." },
  { question: "How do I change my subscription plan?", answer: "You can change or cancel your subscription plan within 24 hours of purchase through your account dashboard." },
  { question: "Is there a withdrawal fee?", answer: "Withdrawal fees are included in your subscription plan. There are no hidden fees, and you will be informed of any applicable charges during the conversion process." },
  { question: "What are the benefits of the Silver plan?", answer: "The Silver plan offers easy cryptocurrency conversions to USDT ERC20/USDT TRC20, ideal for users who want to convert low-value or rarely traded tokens." },
  { question: "What are the benefits of the Gold plan?", answer: "The Gold plan offers advanced exchange features, allowing users to convert fiat currencies like USD, AED, EUR to USDT ERC20/USDT TRC20." },
  { question: "What are the benefits of the Platinum plan?", answer: "The Platinum plan offers premium flexibility, allowing users to convert any fiat or cryptocurrency into USDT ERC20/USDT TRC20 or withdraw directly to a personal or corporate bank account." },
  { question: "How long does a conversion take?", answer: "Conversions are typically processed within minutes, but may take longer depending on the blockchain network." },
  { question: "Can I cancel my subscription at any time?", answer: "Yes, you can change or cancel your subscription plan within 24 hours of purchase." },
  { question: "Is my personal and financial information secure?", answer: "Yes, we prioritize security and ensure the highest measures are in place to protect your information." },
  { question: "Can I use WeMonitize4U for business purposes?", answer: "Yes, our platform is suitable for both individual and business use, with the Platinum plan offering premium features for businesses." },
  { question: "How do I contact customer support?", answer: "You can contact our support team via email, phone, or fax, or visit our office during business hours." },
  { question: "What are the office hours for customer support?", answer: "Our office hours are Monday to Friday, 9 AM to 6 PM (GMT)." },
  { question: "Can I convert cryptocurrencies not listed on the platform?", answer: "Please contact our support team to inquire about converting cryptocurrencies not listed on our platform." },
  { question: "How do I track my conversions?", answer: "You can track your conversions through your account dashboard." },
  { question: "Can I use WeMonitize4U from any country?", answer: "Our services are available globally, but please note that some countries may have restrictions on cryptocurrency transactions." },
  { question: "What is the minimum amount required for a conversion?", answer: "The minimum amount required for a conversion varies depending on the currency and subscription plan." },
  { question: "Can I have multiple accounts?", answer: "No, each user is limited to one account." },
  { question: "How do I report suspicious activity?", answer: "Please contact our support team immediately if you suspect any suspicious activity on your account." }
];

export default Faqs;
