import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { newslatter } from "../../../api/Api";

function Footer() {
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await newslatter(credentials.email);
      if (result && result.status === "success") {
        toast.success(result.message || "Newsletter Subscribe Successfully");
        setCredentials({ email: "" });
      } else {
        toast.error(result.message || "Failed to Sign Up.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <div
      className="container-fluid bg-light footer mt-5 pt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-md-12">
            <a
              href="/"
              className="navbar-brand mb-4 d-flex align-items-center"
            >
              <h2 className="m-0 fw-bold">
                <img
                  className="img-fluid me-2 logo-img"
                  src="/assets/img/logo.png"
                  alt="icon"
                  width={150}
                />
                <span style={{ color: "rgb(22, 213, 255)" }}>
                  {" "}
                  We Monitize4u
                </span>
              </h2>
            </a>
            <span>
              We aim to expand our services to include more currencies,
              introduce new features for institutional clients, and integrate
              decentralized finance solutions, all while maintaining the highest
              compliance standards.
            </span>
          </div>

          <div className="col-md-4">
            <h5 className="mb-4">Get In Touch</h5>
            <p>
              <i className="fa fa-envelope me-3" />
              support@wemonitize4u.com
            </p>
          </div>
          <div className="col-md-4">
            <h5 className="mb-4">Quick Links</h5>
            <a
              className="btn btn-link"
              href="#about"
              style={{ textDecoration: "none" }}
            >
              About Us
            </a>
            <a
              className="btn btn-link"
              href="#contact"
              style={{ textDecoration: "none" }}
            >
              Contact Us
            </a>
            <a
              className="btn btn-link"
              href="#services"
              style={{ textDecoration: "none" }}
            >
              Our Services
            </a>
            <a
              className="btn btn-link"
              href="#features"
              style={{ textDecoration: "none" }}
            >
              Why Us?
            </a>
          </div>
          <div className="col-md-4">
            <h5 className="mb-4">Newsletter</h5>
            <p>Enter Email to Subscribe And Avail Offers</p>
            <div className="position-relative">
              <input
                className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                type="email"
                name="email"
                onChange={onChange}
                value={credentials.email}
                placeholder="Your email"
              />
              <button
                type="button"
                className="btn py-2 px-3 position-absolute top-0 end-0 mt-2 me-2 text-white"
                onClick={handleSubmit}
                style={{ backgroundColor: "#16D5FF" }}
                disabled={loading}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Send Mail"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="text-center mb-3 mb-md-0">
          © {currentYear}{" "}
          <Link to="/" style={{ textDecoration: "none" }}>
            Wemonitize 4U
          </Link>
          , All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default Footer;
