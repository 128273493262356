import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light navbar-fixed p-0 px-4 px-lg-5">
      <a href="/" className="navbar-brand d-flex align-items-center">
        <h2 className="m-0 fw-bold">
          <img
            className="img-fluid me-2 logo-img"
            src="/assets/img/logo.png"
            alt="icon"
            width={110}
            
          />
          <span style={{ color: "rgb(22, 213, 255)" }}>   We Monitize4u</span>
        </h2>
      </a>
      <button
        type="button"
        className="navbar-toggler"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto py-4 py-lg-0">
          <a href="#home" className="nav-item nav-link active">
            Home
          </a>
          <a href="#about" className="nav-item nav-link">
            About
          </a>
          <a href="#features" className="nav-item nav-link">
            Features
          </a>
          <a href="#services" className="nav-item nav-link">
            Services
          </a>
          <a href="#faqs" className="nav-item nav-link">
            Faqs
          </a>
          <a href="#contact" className="nav-item nav-link">
            Contact
          </a>
        </div>
          <div className="h-100 d-lg-inline-flex ">
            <Link to="/login" className="btn btn-block btn-outline-info ">
              Login
            </Link>
            <Link to="/register" className="btn btn-block btn-outline-info ms-2">
              Register
            </Link>
          </div>
      </div>
    </nav>
  );
}

export default Navbar;
