import React from "react";

function SeeMoreModel() {
  return (
    <div
      class="modal fade"
      id="seemore"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              More About Wemonitize4U
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Welcome to *WeMonitize4U*, a trusted platform for converting your
            fiat and cryptocurrency into real money. Whether you need to convert
            low-value crypto tokens or mainstream fiat currencies, we provide
            secure, fast, and flexible transactions. With user-friendly features
            and subscription plans tailored to individual needs, managing your
            assets has never been easier. Convert USD, AED, EUR, and crypto
            tokens to USDT ERC20 or USDT TRC20, and withdraw directly to your
            bank or crypto account. We prioritize convenience, allowing you to
            handle your finances with confidence and simplicity. Choose
            WeMonitize4U to take control of your digital assets today!
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SeeMoreModel;
