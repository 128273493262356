import React from "react";

function Services() {
  return (
    <div className="container-xxl bg-light py-5 my-5" id="services">
      <div className="container py-5">
        <div
          className="text-center mx-auto wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: 500 }}
        >
          <h1 className="display-6">Services</h1>
          <p className="fs-5 mb-5" style={{ color: "#16D5FF" }}>
            Buy, Sell And Monitetize Your Assets
          </p>
        </div>
        <div className="row g-4">
          <div className=" col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item bg-white p-5">
              <img
                className="img-fluid mb-4"
                src="/assets/img/icon-7.png"
                alt="icon"
              />
              <h5 className="mb-3">Wide Range of Fiat Currency Conversion</h5>
              <p>
                WeMonitize4U supports the conversion of major fiat currencies
                like <strong>USD, AED, EUR</strong>, and other commonly used currencies. Users
                can convert these currencies into stablecoins, ensuring they
                have access to secure, value-preserving assets that can be
                easily withdrawn to their crypto wallets or bank accounts.
              </p>
            </div>
          </div>
          <div className=" col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item bg-white p-5">
              <img
                className="img-fluid mb-4"
                src="/assets/img/icon-9.png"
                alt="icon"
              />
              <h5 className="mb-3">Fast and Secure Withdrawals</h5>
              <p>
                Users on the Platinum plan enjoy the added convenience of
                withdrawing their funds directly to personal or corporate bank
                accounts. All fiat withdrawals require <strong>KYC verification</strong> to
                ensure a high level of security and compliance, while
                cryptocurrency withdrawals do not require any additional
                verification, making it fast and easy to manage your funds.
              </p>
            </div>
          </div>
          <div className=" col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item bg-white p-5">
              <img
                className="img-fluid mb-4"
                src="/assets/img/icon-3.png"
                alt="icon"
              />
              <h5 className="mb-3">Cryptocurrency Token Conversion</h5>
              <p>
                {" "}
                Our platform supports the conversion of a wide variety of
                cryptocurrencies, including low value or rarely traded tokens.
                Whether you have niche tokens or mainstream cryptocurrencies,
                you can rely on <strong>WeMonitize4U</strong> to convert your assets to USDT,
                providing a stable and secure asset that can be used for
                transactions or withdrawn to bank accounts.
              </p>
            </div>
          </div>

          <div className=" col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item bg-white p-5">
              <img
                className="img-fluid mb-4"
                src="/assets/img/icon-5.png"
                alt="icon"
              />
              <h5 className="mb-3">Subscription Flexibility</h5>
              <p>
                WeMonitize4U provides complete flexibility in subscription
                plans, allowing users to upgrade, downgrade, or cancel their
                subscription within <strong>24 hours</strong> of purchase. This ensures that
                you are never locked into a plan that doesn't fit your needs,
                giving you full control over your financial decisions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
