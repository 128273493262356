import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import { getAllUsersPriceChart } from "../../../api/Api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Calculator() {
  const [clientRows, setClientRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFiatValue, setSelectedFiatValue] = useState("");
  const [amount, setAmount] = useState("");
  const [result, setResult] = useState(0);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getAllUsersPriceChart();
        if (res.status === "success") {
          setClientRows(res.data);
        } else {
          toast.error("Failed to fetch data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching Data.");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const selectedClient = clientRows.find(client => client.coinName === selectedFiatValue);

    if (selectedClient && amount) {
      const perValue = parseFloat(selectedClient.fiatValue) /parseFloat(selectedClient.amount) ;
      const calculatedResult = perValue * parseFloat(amount);
      setResult(isNaN(calculatedResult) ? 0 : calculatedResult.toFixed(2));
    } else {
      setResult(0);
    }
  }, [amount, selectedFiatValue, clientRows]);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedFiatValue(selectedValue);
  };

  return (
    <>
      <Header title="Virtual Calculator" />
      <div className="container pb-4">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                    <h4 className="text-center fw-bold mb-3">
                      What Do You Want To Convert?
                    </h4>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="form-group">
                      <label htmlFor="fullName">
                        <span className="fw-semibold">Choose What You Have</span>
                      </label>
                      <select
                        className="form-select form-select-md mb-3 p-3"
                        aria-label=".form-select-md example"
                        onChange={handleSelectChange}
                        disabled={loading}  
                      >
                        {loading ? (
                          <option>Loading...</option>
                        ) : (
                          <>
                            <option selected disabled>
                              Open this Select Menu
                            </option>
                            {clientRows.map((client) => (
                              <option key={client._id} value={client.coinName}>
                                {client.coinName} (Fiat Value: {client.fiatValue})
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="form-group">
                      <label htmlFor="fullName">
                        <span className="fw-semibold">Amount To Buy</span>
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        value={amount}
                        onChange={handleAmountChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gutters">
                  <div className="col-md-4 d-flex justify-content-center mt-0 mt-md-5">
                    <FontAwesomeIcon
                      className="display-1 ms-1"
                      icon={faMoneyBillTransfer}
                      style={{ color: "#16D5FF" }}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card mt-3 border-2 border-info">
                      <div className="card-body border-info">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="py-5 fs-4 fw-semibold">${result}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="submitb w-100 p-2">
                      <Link to={'/user/valueunlock'}
                        className="btn btn-block w-100 p-2"
                        style={{ backgroundColor: "#16D5FF" }}
                      >
                        <span className="fw-semibold text-white">
                          Continue to Buy
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Calculator;
