import React, { useEffect, useState } from "react";
import Header from "../user/common/Header";
import { getDashboardData } from "../../api/Api";
import { toast } from "react-toastify";
import Spinner from "../homeComp/commonComp/Spinner";

function Dashboard() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getDashboardData();
        if (res.status === "success") {
          setData(res.data);
        } else {
          toast.error("Failed to fetch data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
     <Header title="Dashboard" />
      <div className="container">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "50vh" }}
          >
            <Spinner />
          </div>
        ) : (
          <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
            <div className="col">
              <div className="card radius-10 border-start border-0 border-3 border-info">
                <div className="card-body p-4">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Total Users</p>
                      <h5 className="my-1 text-info">
                        {data?.userCounts?.totalUsers}
                      </h5>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-info text-white ms-auto">
                      <i className="bi bi-people-fill" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-start border-0 border-3 border-success">
                <div className="card-body p-4">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Verified Users</p>
                      <h5 className="my-1 text-success">
                        {data?.userCounts?.verifiedUsers}
                      </h5>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-success text-white ms-auto">
                      <i className="bi bi-shield-fill-check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-start border-0 border-3 border-warning">
                <div className="card-body p-4">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Pending KYC</p>
                      <h5 className="my-1 text-warning">
                        {data?.userCounts?.pendingKycUsers}
                      </h5>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-warning text-white ms-auto">
                      <i className="bi bi-hourglass-top" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-start border-0 border-3 border-danger">
                <div className="card-body p-4">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Rejected KYC</p>
                      <h5 className="my-1 text-danger">
                        {data?.userCounts?.rejectedKycUsers}
                      </h5>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-danger text-white ms-auto">
                      <i className="bi bi-exclamation-circle-fill" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-start border-0 border-3 border-warning">
                <div className="card-body p-4">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">
                        Pending Bank Withdraw
                      </p>
                      <h5 className="my-1 text-warning">
                        {data?.withdrawalCounts?.bankWithdrawals?.pending}
                      </h5>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-warning text-white ms-auto">
                      <i className="bi bi-cash-stack" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-start border-0 border-3 border-success">
                <div className="card-body p-4">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Accepted Bank Withdraw</p>
                      <h5 className="my-1 text-success">
                      {data?.withdrawalCounts?.bankWithdrawals?.approved}
                      </h5>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-success text-white ms-auto">
                      <i className="bi bi-check2-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-start border-0 border-3 border-warning">
                <div className="card-body p-4">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">
                        Pending Crypto Withdraw
                      </p>
                      <h5 className="my-1 text-warning">
                        {data?.withdrawalCounts?.cryptoWithdrawals?.pending}
                      </h5>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-warning text-white ms-auto">
                      <i className="bi bi-currency-bitcoin" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card radius-10 border-start border-0 border-3 border-success">
                <div className="card-body p-4">
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="mb-0 text-secondary">Accepted Crypto Withdraw</p>
                      <h5 className="my-1 text-success">
                      {data?.withdrawalCounts?.cryptoWithdrawals?.approved}
                      </h5>
                    </div>
                    <div className="widgets-icons-2 rounded-circle bg-success text-white ms-auto">
                      <i className="bi bi-check2-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Dashboard;