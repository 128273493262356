import React from "react";
function Sidebar(props) {
  return (
    <div
      className={`sidebar ${props.toogleNav ? "active" : ""} `}
      id="side_nav"
    >
      <div className="d-flex justify-content-end align-content-end ">
        <button
          className="btn d-md-none d-block close-btn px-1 py-0 text-white"
          onClick={props.setToogleNav}
        >
          {props.toogleNav ? (
            <i className="bi bi-caret-right-square fs-2 fw-bold "></i>
          ) : (
            ""
          )}
        </button>
      </div>
      <a href="/">
        <img
          className="img-fluid mt-3 logo-img-white rounded-5 ms-3"
          src="/assets/img/logo.png"
          alt="icon"
          width={200}
        />
      </a>
      <div className="header-box d-flex justify-content-between">
      <div className="fs-4">
          <h2 className="text-white mt-2 ms-3 fw-bold">Wemonitize4u</h2>
        </div>
      </div>

      <ul className="list-unstyled px-2">
        <li className="d-flex align-items-center ">
          <a
            href="/admin/dashboard"
            className="text-decoration-none py-1 px-3 d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-house-door-fill fs-5"></i>{" "}
            <span className="fw-semibold ">Dashboard</span>
          </a>
        </li>
        <li className="d-flex align-items-center ">
          <a
            href="/admin/users"
            className="text-decoration-none py-1 px-3 d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-people fs-5"></i>{" "}
            <span className="fw-semibold ">All Users</span>
          </a>
        </li>
        <li className="d-flex align-items-center ">
          <a
            href="/admin/subscribed-user"
            className="text-decoration-none py-1 px-3 d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-person-add fs-5"></i>{" "}
            <span className="fw-semibold ">Subscribed Users</span>
          </a>
        </li>
        <li className="d-flex align-items-center ">
          <a
            href="/admin/addchart"
            className="text-decoration-none py-1 px-3  d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-graph-down fs-5"></i>{" "}
            <span className="fw-semibold ">Price Chart</span>
          </a>
        </li>
        <li className="d-flex align-items-center ">
          <a
            href="/admin/create-subscription"
            className="text-decoration-none py-1 px-3  d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-bookmarks fs-5"></i>{" "}
            <span className="fw-semibold ">Create Subscription</span>
          </a>
        </li>
        <li className="d-flex align-items-center ">
          <a
            href="/admin/bank-withdraw"
            className="text-decoration-none py-1 px-3  d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-bank fs-5"></i>{" "}
            <span className="fw-semibold ">Bank Withdraw </span>
          </a>
        </li>
        <li className="d-flex align-items-center ">
          <a
            href="/admin/crypto-withdraw"
            className="text-decoration-none py-1 px-3 d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-currency-bitcoin fs-5"></i>{" "}
            <span className=" fw-semibold">Crypto Withdraw </span>
          </a>
        </li>
        <li className="d-flex align-items-center ">
          <a
            href="/admin/manage-valueunlock"
            className="text-decoration-none py-1 px-3 d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-unlock fs-5"></i>{" "}
            <span className=" fw-semibold">Manage Value Unlock </span>
          </a>
        </li>
        <li className="d-flex align-items-center ">
          <a
            href="/admin/transations"
            className="text-decoration-none py-1 px-3 d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-currency-dollar fs-5"></i>{" "}
            <span className=" fw-semibold">Transation </span>
          </a>
        </li>
        <li className="d-flex align-items-center ">
          <a
            href="/admin/support"
            className="text-decoration-none py-1 px-3 d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-phone fs-5"></i>
            <span className="fw-semibold">Support</span>
          </a>
        </li>
        <li className="d-flex align-items-center ">
          <a
            href="/admin/change-password"
            className="text-decoration-none py-1 px-3 d-block d-flex align-items-center gap-2"
          >
            <i className="bi bi-eye fs-5"></i>
            <span className="fw-semibold">Change Password</span>
          </a>
        </li>
      </ul>
      <hr className="text-white fw-bold mx-2" />
    </div>
  );
}

export default Sidebar;
