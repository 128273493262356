import React from "react";
import SeeMoreModel from "./SeeMoreModel";

function Header() {
  return (
    <div className="container-fluid hero-header bg-light py-5 mb-5" id="home">
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 order-2 order-sm-1">
            <h1 className="display-5 mb-3 animated slideInDown">
            A Wide Range of Services to Meet your Asset Conversion
            </h1>
            <p className="animated slideInDown">
              Welcome to <strong>WeMonitize4U</strong>, a trusted platform for converting your
              fiat and cryptocurrency into real money. Whether you need to
              convert low-value crypto tokens or mainstream fiat currencies, we
              provide secure, fast, and flexible transactions.
            </p>
            <button
              type="button"
              className="btn py-3 px-4 animated slideInDown fw-semibold text-white"
              style={{ backgroundColor: "#16D5FF" }}
              data-bs-toggle="modal" data-bs-target="#seemore"
            >
              See More
            </button>
          </div>
          <div className="col-lg-6 animated fadeIn order-1 order-sm-2">
            <img
              className="img-fluid animated pulse infinite"
              style={{ animationDuration: "3s" }}
              src="/assets/img/hero-1.png"
              alt="Hero"
            />
          </div>
        </div>
      </div>
      <SeeMoreModel />
    </div>
  );
}

export default Header;
