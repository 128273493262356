import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/actions/logoutUser";
import { setUser } from "../../../redux/actions/userProfile";
import { getUserData } from "../../../api/Api"; 
import { useNavigate } from "react-router-dom";
import CoinMarque from "./CoinMarque";

function Topnav(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.userData.token);
  const subscriptionValidUntil = useSelector(
    (state) => state.userData.user.subscriptionValidUntil
  );

  const [remainingDays, setRemainingDays] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState("loading");

  useEffect(() => {
    if (token === "") {
      navigate("/login");
    }
  }, [token, navigate]);

  useEffect(() => {
    const checkSubscriptionAndUpdateUser = async () => {
      if (!subscriptionValidUntil) {
        setSubscriptionStatus("notPurchased");
      } else {
        const currentDate = new Date();
        const expirationDate = new Date(subscriptionValidUntil);
        const timeDifference = expirationDate - currentDate;
        const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        setRemainingDays(daysRemaining);

        if (daysRemaining > 0) {
          setSubscriptionStatus("active");
        } else {
          setSubscriptionStatus("expired");
        }

        if (daysRemaining <= 0) {
          try {
            const userData = await getUserData(token);
            dispatch(setUser(userData.data)); 
          } catch (error) {
            console.error("Failed to fetch user data:", error);
          }
        }
      }
    };

    checkSubscriptionAndUpdateUser();
  }, [subscriptionValidUntil, token, dispatch]);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  return (
    <div>
      <header
        className="d-flex flex-wrap align-items-end justify-content-start py-3 mb-4 pe-md-4"
        style={{ backgroundColor: "#16D5FF" }}
      >
        <div>
          <button
            className="btn px-1 py-0 toggle-btn d-md-none d-block me-2"
            onClick={props.setToogleNav}
          >
            {props.toogleNav ? "" : <i className="bi bi-list fs-2 fw-bold text-white"></i>}
          </button>
        </div>
        <div className="ms-auto">
          <button
            type="button"
            className="btn btn-block me-4"
            style={{ backgroundColor: "white" }}
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </header>

      <div className="subscription-status text-center my-3">
        {subscriptionStatus === "loading" ? (
          <p>Loading subscription details...</p>
        ) : subscriptionStatus === "notPurchased" ? (
          <p style={{ color: "orange" }}>You have not purchased any subscription yet.</p>
        ) : subscriptionStatus === "active" ? (
          <p>Your subscription is valid for {remainingDays} day(s).</p>
        ) : (
          <p style={{ color: "red" }}>Your subscription has expired. Please update it.</p>
        )}
      </div>

      <CoinMarque />
    </div>
  );
}

export default Topnav;
