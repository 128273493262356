import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AdminLoginEmail } from "../../api/Api";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../../redux/actions/userProfile";

function AdminLogin() {
  const navigate = useNavigate();
  const tokken = useSelector((state) => state.userData.token);
  const userData = useSelector((state) => state.userData.user);

  useEffect(() => {
    if (tokken !== "" && userData ==="admin" ) {
      navigate("/admin/dashboard"); 
    }
  }, [tokken,userData, navigate]);
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false); 

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); 
    try {
      const result = await AdminLoginEmail(credentials);
      if (result && result.status === "success") {
        const token = await result.authToken;
        dispatch(setToken(token));
        dispatch(setUser(result.role));
        toast.success(result.message || "Login Successful");
          navigate("/admin/dashboard");
      } else {
        toast.error(result.message || "Failed to Login.");
      }
    } catch (error) {
      toast.error("An error occurred during login.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-md-6 col-lg-6 col-12">
            <div className="d-flex justify-content-center align-items-center">
              <div className="card w-75 mt-4">
                <div className="card-body">
                  <div className="text-center">
                    <img
                      src="/assets/img/logo.png"
                      alt="icon"
                      className="mb-3 logo-img"
                      width={150}
                    />
                  </div>
                  <h1
                    className="card-subtitle text-center mb-4 display-4 fw-semibold"
                    style={{ color: "#16D5FF" }}
                  >
                    Admin
                  </h1>
                  <form onSubmit={handleLogin}>
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={credentials.email}
                        required
                        onChange={onChange}
                        autoComplete="email"
                      />
                      <label>Email</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={credentials.password}
                        onChange={onChange}
                        required
                        autoComplete="current-password"
                      />
                      <label>Password</label>
                    </div>
                    <div className="btn-regg">
                      <button
                        className="btn btn-block w-100 p-2"
                        type="submit"
                        style={{ backgroundColor: "#16D5FF" }}
                        disabled={loading} 
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          <span className="text-white fw-semibold">Sign In</span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
