import { toast } from "react-toastify";
import { get, post, put, del } from "./apiRequests";

// Single Image Upload
export async function singleImageUpload(formData, userToken) {
  try {
    const response = await post(
      "upload/imageUpload",
      formData,
      userToken,
      "formData"
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// User Signup
export async function signup(credentials) {
  try {
    const response = await post("auth/register", {
      fullName: credentials.fullName,
      email: credentials.email,
      password: credentials.password,
    });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// User Login
export async function loginWithEmail(userData) {
  try {
    const response = await post("auth/login", userData);
    return response;
  } catch (error) {
    toast.error(error?.message || "Something went wrong", "error");
  }
}

// Forget Password with Email
export async function forgotPassword(email) {
  try {
    const response = await post("auth/forgotPassword", { email });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// Newslatter with Email
export async function newslatter(email) {
  try {
    const response = await post("newsLetter/subscribe", { email });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

//Reset Password With Token
export async function resetPassword(password, userToken) {
  try {
    const response = await post("auth/resetPassword", password, userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get User Data
export async function getUserData(userToken) {
  try {
    const response = await get("user/getUserDetails", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Update User Data
export async function updateUserData(userData, userToken) {
  try {
    const response = await put(
      "user/updateUserDetails",
      userData,
      userToken,
      "json"
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Update KYC
export async function updateKyc(userData, userToken) {
  try {
    const response = await post("kyc/create", userData, userToken, "json");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// contact from
export async function contactForm(credentials) {
  try {
    const response = await post("contactus", {
      name: credentials.name,
      email: credentials.email,
      subject: credentials.subject,
      message: credentials.message,
    });
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

//admin Login with Email
export async function AdminLoginEmail(userData) {
  try {
    const response = await post("admin/login", userData);
    return response;
  } catch (error) {
    toast.error(error?.message || "Something went wrong", "error");
  }
}
// Get All Users
export async function getAllUsers(userToken) {
  try {
    const response = await get("admin/getAllUsers", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// user status
export async function userStatus(userData) {
  try {
    const response = await put("admin/user/changeStatus", userData);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

//User Withdraw with bank
export async function userBankWithdraw(userData, userToken) {
  try {
    const response = await post("create/bankwithdraw", userData, userToken);
    return response;
  } catch (error) {
    toast.error(error?.message || "Something went wrong", "error");
  }
}

//User Withdraw with Crypto
export async function userCryptoWithdraw(userData, userToken) {
  try {
    const response = await post("create/crypto", userData, userToken);
    return response;
  } catch (error) {
    toast.error(error?.message || "Something went wrong", "error");
  }
}

// Get Users Withdraw History
export async function getWithdrawHistory(userToken) {
  try {
    const response = await get("getall/user/details", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All c2b withdraw users
export async function getAllBankDetails(userToken) {
  try {
    const response = await get("admin/bankDetails", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All crypto withdraw users
export async function getAllCryptoDetails(userToken) {
  try {
    const response = await get("admin/cryptoDetails", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// admin status bank approved
export async function ApprovedStatusBank(id) {
  try {
    const response = await put(`admin/withdraw/approve/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// admin status crypto approved
export async function ApprovedStatusCrypto(id) {
  try {
    const response = await put(`admin/crypto/approve/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// admin status bank disapproved
export async function DisapprovedStatusBank(id) {
  try {
    const response = await put(`admin/withdraw/disapprove/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// admin status crypto disapproved
export async function DisapprovedStatusCrypto(id) {
  try {
    const response = await put(`admin/crypto/disapprove/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// user Get All price chart
export async function getAllUsersPriceChart(userToken) {
  try {
    const response = await get("admin/getall/priceCharts", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

//Admin Pricechart
export async function priceChart(userData) {
  try {
    const response = await post("admin/create/priceCharts", userData);
    return response;
  } catch (error) {
    toast.error(error?.message || "Something went wrong", "error");
  }
}

// Delete Price Chart value
export async function DeleteChartValue(id) {
  try {
    const response = await del(`admin/delete/priceCharts/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

//Admin Update Pricechart
export async function priceChartUpdate(userData, id) {
  try {
    const response = await put(`admin/update/priceCharts/${id}`, userData);
    return response;
  } catch (error) {
    toast.error(error?.message || "Something went wrong", "error");
  }
}

// Get All Users by id
export async function getAllUsersById(id) {
  try {
    const response = await get(`user/get/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// user Get All price chart
export async function getAllSubscription(userToken) {
  try {
    const response = await get("admin/getall/subscriptions", userToken);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Delete Subscription price Chart
export async function DeleteSubscriptionChart(id) {
  try {
    const response = await del(`admin/delete/subscriptions/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

//Create Subscription
export async function CreateSubscription(userData) {
  try {
    const response = await post("admin/create/subscriptions", userData);
    return response;
  } catch (error) {
    toast.error(error?.message || "Something went wrong", "error");
  }
}


// admin status kyc approved
export async function ApprovedStatusKyc(id) {
  try {
    const response = await put(`admin/kyc/approve/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// admin status kyc disapproved
export async function DisapprovedStatusKyc(id) {
  try {
    const response = await put(`admin/kyc/disapprove/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Change Admin Password With Token
export async function changeAdminPassword(oldPassword, newPassword, userToken) {
  try {
    const response = await put(
      "admin/changePassword",
      { oldPassword, newPassword }, 
      userToken
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Change User Password With Token
export async function changeUserPassword(currentPassword, newPassword, userToken) {
  try {
    const response = await put(
      "auth/changePassword",
      { currentPassword, newPassword }, 
      userToken
    );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Post Value unlock admin
export async function createValueUnlock(userData) {
  try {
    const response = await post("admin/create/valueunlock", userData);
    return response;
  } catch (error) {
    toast.error(error?.message || "Something went wrong", "error");
  }
}

// Get value unlock Data
export async function getallValueUnlock() {
  try {
    const response = await get("admin/getall/valueunlock");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get value unlock Data by type
export async function getallValueByType(type) {
  try {
    const response = await get(`get?currencyType=${type}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Delete value unlock by id
export async function deleteValueUnlock(id) {
  try {
    const response = await del(`admin/delete/valueunlock/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Post Value unlock Transation
export async function valueUnlockTransation(userData) {
  try {
    const response = await post("transaction/create", userData);
    return response;
  } catch (error) {
    toast.error(error?.message || "Something went wrong", "error");
  }
}

// Get All crypto withdraw users
export async function getAllTransations() {
  try {
    const response = await get("get/transactions", );
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// admin status crypto approved
export async function approvedTransation(id) {
  try {
    const response = await put(`transaction/approve/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}
// admin status bank disapproved
export async function disapprovedTransation(id) {
  try {
    const response = await put(`transaction/disapprove/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Get All crypto withdraw users
export async function getUserTransations(id) {
  try {
    const response = await get(`transactions/user/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// user Get Contact us
export async function getAllContactus() {
  try {
    const response = await get("admin/contactus/getall");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// admin Dashboard data
export async function getDashboardData() {
  try {
    const response = await get("admin/userCounts");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// user Dashboard data
export async function getUserDashboardData(id) {
  try {
    const response = await get(`user/detailsCounts/${id}`);
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}

// Post Subcription
export async function createSubscription(userData) {
  try {
    const response = await post("subscription/payments", userData);
    return response;
  } catch (error) {
    toast.error(error?.message || "Something went wrong", "error");
  }
}

// Get All crypto withdraw users
export async function getSubscribedUsers() {
  try {
    const response = await get("subscription/getall/payments");
    return response;
  } catch (error) {
    toast.error(error.message || "Something went wrong", "error");
  }
}