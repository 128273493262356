import React from "react";

function Features() {
  return (
    <div className="container-xxl py-5" id="features">
      <div className="container">
        <div
          className="text-center mx-auto wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: 500 }}
        >
          <h1 className="display-6">Why Us!</h1>
          <p className="fs-5 mb-5" style={{ color: "#16D5FF" }}>
            WeMonitize4U stands out in the world of asset conversion for
            several reasons:
          </p>
        </div>
        <div className="row g-5">
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="d-flex align-items-start">
              <i className="text-info bi bi-currency-exchange display-3"></i>
              <div className="ps-4">
                <h5 className="mb-3">Easy Currency Conversion</h5>
                <span>
                  Convert a wide range of fiat currencies and cryptocurrency
                  tokens into USDT or direct bank withdrawals with ease.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="d-flex align-items-start">
              <i className="text-info bi bi-card-list display-3"></i>
              <div className="ps-4">
                <h5 className="mb-3">Subscription Flexibility</h5>
                <span>
                  Our subscription plans Silver, Gold, and Platinum cater to all
                  types of users, from beginners to advanced traders and
                  businesses.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="d-flex align-items-start">
              <i className="text-info bi bi-shield-lock display-3"></i>
              <div className="ps-4">
                <h5 className="mb-3">Security</h5>
                <span>
                  We ensure the highest security measures for all transactions,
                  including KYC verification for fiat withdrawals, ensuring your
                  funds are protected.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="d-flex align-items-start">
              <i className="text-info bi bi-lock display-3"></i>
              <div className="ps-4">
                <h5 className="mb-3">No Verification for Crypto</h5>
                <span>
                  Fast and hassle-free cryptocurrency conversions without any
                  verification, allowing quick access to your funds.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="d-flex align-items-start">
              <i className="text-info bi bi-globe display-3"></i>
              <div className="ps-4">
                <h5 className="mb-3">Global Reach</h5>
                <span>
                  We support a wide variety of fiat and crypto currencies,
                  making cross-border conversions effortless.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="d-flex align-items-start">
              <i className="text-info bi bi-clock-history display-3"></i>
              <div className="ps-4">
                <h5 className="mb-3">24-Hour Plan Flexibility</h5>
                <span>
                  Change or cancel your subscription plan within 24 hours of
                  purchase, giving you full control over your experience.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="d-flex align-items-start">
              <i className="text-info bi bi-headset display-3"></i>
              <div className="ps-4">
                <h5 className="mb-3">Dedicated Support</h5>
                <span>
                  Our team is always here to help you with any queries, ensuring
                  a smooth user experience from start to finish.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
