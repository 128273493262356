import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserDashboardData, userCryptoWithdraw } from "../../../api/Api";
import { toast } from "react-toastify";

function Cryptowidthdraw() {
  const userData = useSelector((state) => state.userData.user);
  const userToken = useSelector((state) => state.userData.token);

  const [crypto, setCrypto] = useState({
    usdt: "",
    btc: "",
    amount: "",
  });

  const [selectedCrypto, setSelectedCrypto] = useState(""); 
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const id = useSelector((state) => state.userData.user._id);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); 
      try {
        const res = await getUserDashboardData(id);
        if (res.status === "success") {
          setTotal(res.data.totalAmount); 
        } else {
          toast.error("Failed to fetch data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching data.");
      } finally {
        setLoading(false); 
      }
    };
    fetchData();
  }, [id]);

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
      if (parseFloat(value) > total) {
        toast.error(`Sorry! Your Have Total Amount of $${total}`);
        return;
      }
      if (parseFloat(value) === 0) {
        toast.error(`Sorry! Value must be greater then 0`);
        return;
      }
    }

    setCrypto({ ...crypto, [name]: value });
  };

  const handleCrypto = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await userCryptoWithdraw(crypto, userToken);
    if (result && result.status === "success") {
      toast.success(result.message || "Withdraw Request Successfully Created");
      setCrypto({
        usdt: "",
        btc: "",
        amount: ""
      });
      setSelectedCrypto("");
    } else {
      toast.error(result.message || "Failed to Make Request.");
    }
    setLoading(false);
  };

  const handleCryptoChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCrypto(selectedValue);
    setCrypto({ ...crypto, usdt: selectedValue });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 mt-2">
          <div className="card pb-2 h-100">
            <div className="card-body">
              <h4 className="mb-3 text-info">Your Crypto Details</h4>
              <form onSubmit={handleCrypto}>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="fullName">
                        <span className="fw-semibold">Full Name</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="fullName"
                        value={userData.fullName}
                        placeholder="Enter Full Name"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="usdt">
                        <span className="fw-semibold">Select Crypto</span>
                      </label>
                      <select
                        className="form-control mt-1"
                        name="usdt"
                        value={selectedCrypto}
                        onChange={handleCryptoChange}
                        required
                      >
                        <option value="" disabled>
                          Choose Any
                        </option>
                        <option value="USDT TRC20">USDT TRC20</option>
                        <option value="USDT ERC20">USDT ERC20</option>
                        <option value="BTC">BTC</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="address">
                        <span className="fw-semibold">Address</span>
                      </label>
                      <input
                        type="text"
                        className="form-control mt-1"
                        name="btc"
                        onChange={onChange}
                        placeholder="Address"
                        value={crypto.btc}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                    <div className="form-group">
                      <label htmlFor="amount">
                        <span className="fw-semibold">Amount</span>
                      </label>
                      <input
                        type="number"
                        className="form-control mt-1"
                        name="amount"
                        onChange={onChange}
                        placeholder="Amount"
                        value={crypto.amount}
                        required
                        min="0"
                        max={total} 
                      />
                    </div>
                  </div>
                </div>
                <div className="text-right mt-3 d-flex justify-content-end">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-info ms-2 text-white"
                  >
                    {loading ? "Processing..." : "Withdraw"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cryptowidthdraw;
