import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Spinner from "../components/homeComp/commonComp/Spinner";
import { toast } from "react-toastify";
import { resetPassword } from "../api/Api";
import { useSelector } from "react-redux";

function UpdatePassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const tokken = useSelector((state) => state.userData.token);
  useEffect(() => {
    if (tokken !== "") {
      navigate("/user/dashboard"); 
    }
  }, [tokken, navigate]);
  const { state } = location;
  const [credentials, setCredentials] = useState({
    password: "",
    confirmpassword: "",
  });
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    // Validate password length
    if (credentials.password.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }

    // Check if passwords match
    if (credentials.password === credentials.confirmpassword) {
      setLoading(true);
      try {
        const result = await resetPassword(
          { password: credentials.password },
          state.token
        );
        setLoading(false);

        if (result && result.status === "success") {
          toast.success("Your Password has been Updated");
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        } else {
          toast.error(result.message || "Failed to reset password.");
        }
      } catch (error) {
        toast.error("An error occurred during the password reset.");
        setLoading(false);
      }
    } else {
      toast.error("Password and Confirm Password do not match");
    }
  };

  return (
    <>
      <Spinner />
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100">
          <div className="col-md-6 col-lg-6 col-sm-12 d-none d-md-block">
            <div className="text-center d-flex justify-content-center align-items-center">
              <img
                src="/assets/img/forgetpass.png"
                alt="icon"
                className="h-75 w-75"
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-sm-12">
            <div className="d-flex justify-content-center align-items-center">
              <div className="card w-75 mt-4">
                <div className="card-body">
                  <div className="text-center">
                    <Link to="/">
                      <img
                        src="/assets/img/logo.png"
                        alt="icon"
                        className="mb-3 logo-img"
                        width={150}
                      />
                    </Link>
                  </div>
                  <h1
                    className="card-subtitle text-center mb-4 display-4 fw-semibold"
                    style={{ color: "#16D5FF" }}
                  >
                    Update Password
                  </h1>
                  <form onSubmit={handlePasswordReset}>
                    <div className="form-floating mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={credentials.password}
                        onChange={onChange}
                        required
                      />
                      <label htmlFor="floatingPassword">Password</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        name="confirmpassword"
                        value={credentials.confirmpassword}
                        onChange={onChange}
                        required
                      />
                      <label htmlFor="floatingConfirmPassword">
                        Confirm Password
                      </label>
                    </div>
                    <div className="btn-regg">
                      <button
                        className="btn btn-block w-100 p-2"
                        type="submit"
                        style={{ backgroundColor: "#16D5FF" }}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          <span className="text-white fw-semibold">Submit</span>
                        )}
                      </button>
                    </div>
                    <div className="mt-2">
                      <span>Already Registered? </span>
                      <Link
                        className="btn-link text-info ms-auto fw-semibold"
                        to="/login"
                        style={{ textDecoration: "none" }}
                      >
                        Login Here
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatePassword;
