import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgetPassword from "./pages/ForgetPassword";
import Sidebar from "./components/user/common/Sidebar";
import Dashboard from "./pages/user/Dashboard";
import C2B from "./pages/user/C2B";
import Subscription from "./pages/user/Subscription";
import Setting from "./pages/user/Setting";
import Verification from "./pages/user/Verification";
import PriceChart from "./pages/user/PriceChart";
import ValueUnlock from "./pages/user/ValueUnlock";
import VirtualCalculator from "./pages/user/VirtualCalculator";
import Otp from "./pages/Otp";
import { ToastContainer } from "react-toastify";
import RegisterOtp from "./pages/RegisterOtp";
import UpdatePassword from "./pages/UpdatePassword";
import AdminDashboard from "./pages/admin/AdminDashbaord";
import ChangePassword from "./pages/admin/ChangePassword";
import AllUsers from "./pages/admin/AllUsers";
import AdminLogin from "./pages/admin/AdminLogin";
import AddChart from "./pages/admin/AddChart";
import C2bWithdraw from "./pages/admin/C2bWithdraw";
import CryptoWithdraw from "./pages/admin/CryptoWithdraw";
import Users from "./pages/admin/Users";
import CreateSubscription from "./pages/admin/CreateSubscription";
import ManageValueUnlock from "./pages/admin/ManageValueUnlock";
import TransationTab from "./pages/admin/TransationTab";
import ContactTable from "./pages/admin/ContactTable";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFail from "./pages/PaymentFail";
import SubscribedUsers from "./pages/admin/SubscribedUsers";
function App() {
  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register/otp" element={<RegisterOtp />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/side" element={<Sidebar />} />
        <Route path="/user/dashboard" element={<Dashboard />} />
        <Route path="/user/withdraw" element={<C2B />} />
        <Route path="/user/subscription" element={<Subscription />} />
        <Route path="/user/setting" element={<Setting />} />
        <Route path="/user/verify" element={<Verification />} />
        <Route path="/user/pricechart" element={<PriceChart />} />
        <Route path="/user/valueunlock" element={<ValueUnlock />} />
        <Route path="/forget/otp" element={<Otp />} />
        <Route path="/forgetpassword/update" element={<UpdatePassword />} />
        <Route path="/user/virtualcalculator" element={<VirtualCalculator />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/change-password" element={<ChangePassword />} />
        <Route path="/admin/users" element={<AllUsers />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/addchart" element={<AddChart />} />
        <Route path="/admin/bank-withdraw" element={<C2bWithdraw />} />
        <Route path="/admin/crypto-withdraw" element={<CryptoWithdraw />} />
        <Route path="/admin/manage-valueunlock" element={<ManageValueUnlock />} />
        <Route path="/admin/user/:id" element={<Users />} />
        <Route path="/admin/transations" element={<TransationTab />} />
        <Route path="/admin/subscribed-user" element={<SubscribedUsers />} />
        <Route path="/admin/support" element={<ContactTable />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />
        <Route path="/payment/failure" element={<PaymentFail />} />
        <Route
          path="/admin/create-subscription"
          element={<CreateSubscription />}
        />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
