import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import TransationHistory from "./TransationHistory";
import ValueTable from "./ValueTable";
import { toast } from "react-toastify";
import { getUserDashboardData } from "../../../api/Api";
import { useSelector } from "react-redux";
import Spinner from "../../homeComp/commonComp/Spinner";

function Dash() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const id = useSelector((state) => state.userData.user._id);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); 
      try {
        const res = await getUserDashboardData(id);
        if (res.status === "success") {
          setData(res.data);
        } else {
          toast.error("Failed to fetch data.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching data.");
      } finally {
        setLoading(false); 
      }
    };
    fetchData();
  }, [id]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Header title="Dashboard" />
      <div
        className="container"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        data-wow-offset={0}
        style={{
          visibility: "visible",
          animationDuration: "1s",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
        <div className="col">
            <div className="card radius-10 border-start border-0 border-3 border-secondary">
              <div className="card-body p-4">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="mb-0 text-secondary">Total Balance in Account</p>
                    <h4 className="my-1 text-secondary">${data?.totalAmount}</h4>
                  </div>
                  <div className="widgets-icons-2 rounded-circle bg-secondary bg-gradient text-white ms-auto">
                    <i className="bi bi-cash" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10 border-start border-0 border-3 border-info">
              <div className="card-body p-4">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="mb-0 text-secondary">Bank Transations</p>
                    <h4 className="my-1 text-info">${data?.bankDetailsTotalAmount}</h4>
                  </div>
                  <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                    <i className="bi bi-bank" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10 border-start border-0 border-3 border-danger">
              <div className="card-body p-4">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="mb-0 text-secondary">Crypto Transations</p>
                    <h4 className="my-1 text-danger">${data?.cryptoDetailsTotalAmount}</h4>
                  </div>
                  <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                    <i className="bi bi-currency-bitcoin" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card radius-10 border-start border-0 border-3 border-success">
              <div className="card-body p-4">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="mb-0 text-secondary">Value Unlock Transactions</p>
                    <h4 className="my-1 text-success">${data?.transactionsTotalAmount}</h4>
                  </div>
                  <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                    <i className="bi bi-arrow-left-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mb-5 pb-3"
        data-wow-duration="1s"
        data-wow-delay="0.1s"
        data-wow-offset={0}
        style={{
          visibility: "visible",
          animationDuration: "1s",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <TransationHistory />
        <ValueTable />
      </div>
    </>
  );
}

export default Dash;
