import React, { useEffect, useState } from "react";
import Header from "../user/common/Header";
import { toast } from "react-toastify";
import { getSubscribedUsers } from "../../api/Api";
import Spinner from "../homeComp/commonComp/Spinner";
import DataTable from "react-data-table-component";

function SubscribedUserTable() {
  const [clientRows, setClientRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const res = await getSubscribedUsers();

        if (res.status === "success") {
          setClientRows(res.payments);
          setFilteredRows(res.payments); 
        } else {
          toast.error("Failed to fetch clients.");
        }
      } catch (error) {
        toast.error("An error occurred while fetching clients.");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  // Function to format date to dd/mm/yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const filteredData = clientRows.filter(
      (client) =>
        (client.userId?.fullName?.toLowerCase() || "").includes(
          searchText.toLowerCase()
        ) ||
        (client.userId?.email?.toLowerCase() || "").includes(
          searchText.toLowerCase()
        ) ||
        (client.subscriptionId?.title?.toLowerCase() || "").includes(
          searchText.toLowerCase()
        ) ||
        (client.subscriptionId?.price?.toString().toLowerCase() || "").includes(
          searchText.toLowerCase()
        ) ||
        (formatDate(client.createdAt).toLowerCase() || "").includes(
          searchText.toLowerCase()
        )
    );
    setFilteredRows(filteredData);
  }, [searchText, clientRows]);

  const columns = [
    { name: "#", selector: (row, index) => index + 1, sortable: true },
    { name: "Name", selector: (row) => row.userId.fullName, sortable: true },
    { name: "Email", selector: (row) => row.userId.email, sortable: true },
    { name: "Subscription Plan", selector: (row) => row.subscriptionId?.title, sortable: true },
    { name: "Subscription Price", selector: (row) => row.subscriptionId?.price },
    {
      name: "Date",
      selector: (row) => formatDate(row.createdAt), // Format createdAt to dd/mm/yyyy
      sortable: true,
    },
  ];

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Header title="Subscribed Users" />
      <div className="container">
        <div className="row gutters">
          <div className="col-12">
            <div className="card mt-4">
              <div className="card-body">
                <div className="mb-3 w-100 d-flex justify-content-end">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="form-control w-25"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
                <div className="table-responsive table-scroll">
                  <DataTable
                    columns={columns}
                    data={filteredRows}
                    pagination
                    highlightOnHover
                    striped
                    noHeader
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribedUserTable;
